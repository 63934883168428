import React, {useEffect, useState} from 'react';
import {Menu} from '@headlessui/react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import moment from 'moment';
import {connect} from 'react-redux';
import {
  check_export,
  suspend,
  resend,
  // totalCompanies,
  // unverifiedCompanies,
  // verifiedCompanies,
  verify,
  reactivate,
  logo,
  eye,
  success_svg,
  edit,
  user,
} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import Dropdown from '../../components/Dropdown';
import PrimaryButton from '../../components/PrimaryButton';
import TableLayout from '../../components/TableLayout';
import LayoutContainer from '../../components/LayoutContainer';
import Pagination from '../../components/Pagination';
import {
  // fetchCompanySummary,
  mutateCompany,
  fetchCompanies,
  exportCompanies,
  createCompany,
} from '../../helpers/queryFns/company';
import GoToTop from '../../components/GoToTop';
import Loader from '../../components/Loader';
import {SearchInput} from '../../components/SearchInput';
import {EmptyState} from '../../components/EmptyState';
import Modal from '../../components/Modal';
import {formatNumberToCurrency} from '../../helpers/formatNumberToCurrency';
import CreateCompanyForm from '../../components/CreateCompanyForm';
import EditCompanyNameModal from '../../components/EditCompanyNameModal';
import {updateCompanyInfo} from '../../helpers/queryFns/fetchCompanyDetails';
import AssignClientPartnerModal from '../../components/AssignClientPartnerModal';
import RevokeClientPartnerModal from '../../components/RevokeClientPartnerModal';
import {SuccessDialog} from '../../components/Company/ClientPartnerAssignSuccessDialog';

function ReconciloCompany({company: {settings}}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const page = Number(query.get('page'));
  const filter = query.get('filter');
  const [searchText, setSearchText] = useState(filter);
  const [input, setInput] = useState({});
  const [open, setOpen] = useState(false);
  const [openCreateCompany, stOpenCreateCompany] = useState(false);
  const [resent, setResent] = useState(false);
  const [openCreateCompanySuccess, setOpenCreateCompanySuccess] =
    useState(false);
  const [editCompanyNameModal, setEditCompanyNameModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [assignClientPartner, setAssignClientPartner] = useState(false);
  const [revokeClientPartner, setRevokeClientPartner] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const RECONCILO_COMPANY_ID = '66d20faf2a90a7da60fddf44';

  const handleChangeCurrentPage = e => {
    navigate(`?page=${e}`);
  };

  useEffect(() => {
    if (openCreateCompanySuccess || resent) {
      setTimeout(() => {
        setOpenCreateCompanySuccess(false);
        setResent(false);
      }, 4000);
    }
  }, [openCreateCompanySuccess, resent]);

  // const {data} = useQuery('company-summary', fetchCompanySummary);

  const {isFetching: fetchingCompanyExport, refetch: refetchingCompanyExport} =
    useQuery('export-company', () => exportCompanies(), {
      enabled: false,
      onSuccess: () => {
        setOpen(false);
      },
    });

  const {
    data: companyData,
    isLoading,
    isFetching,
  } = useQuery(
    [
      'company-data',
      {
        name: searchText,
        page: page === 0 ? 1 : page,
        parent_company: RECONCILO_COMPANY_ID,
      },
    ],
    () =>
      fetchCompanies({
        name: searchText,
        page: page === 0 ? 1 : page,
        parent_company: RECONCILO_COMPANY_ID,
      }),
  );
  const {mutate} = useMutation(mutateCompany, {
    onSuccess: res => {
      toast.success(res.message);
      queryClient.invalidateQueries('company-data');
      queryClient.invalidateQueries('company-summary');
    },
    onError: error => {
      const errorMessage = error.response?.data?.error || 'An error occurred';
      toast.error(errorMessage);
    },
  });

  const {mutate: companyInvite, isLoading: creatingCompany} = useMutation(
    createCompany,
    {
      onSuccess: ({message}) => {
        toast.success(
          message || 'An invite has been sent to the email address',
        );
        stOpenCreateCompany(false);
        setOpenCreateCompanySuccess(true);
        queryClient.invalidateQueries('company-data');
      },
      onError: error => {
        toast.error(error.response?.data?.error);
      },
    },
  );

  const {mutate: editCompanyName, isLoading: editingCompanyName} = useMutation(
    info => updateCompanyInfo(selectedCompany?.id, info),
    {
      onSuccess: () => {
        toast.success('Updated company name successfully');
        queryClient.invalidateQueries('company-data');
        setEditCompanyNameModal(false);
      },
      onError: error => {
        const errorMessage = error.response?.data?.error || 'An error occurred';
        toast.error(errorMessage);
      },
    },
  );

  const onCompanyInvite = e => {
    e.preventDefault();
    companyInvite({
      ...input,
      parent_company: input?.parent_company?.value,
    });
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleChange = e => {
    const {id, value} = e.target;
    setInput({...input, [id]: value});
  };

  const action = selectedCompany?.client_partner ? 'unassigned' : 'assigned';

  return (
    <DashboardLayout headerText="Company">
      <EditCompanyNameModal
        open={editCompanyNameModal}
        setOpen={setEditCompanyNameModal}
        selectedCompany={selectedCompany}
        editCompanyName={editCompanyName}
        editingCompanyName={editingCompanyName}
      />
      <AssignClientPartnerModal
        open={assignClientPartner}
        setOpen={setAssignClientPartner}
        selectedCompany={selectedCompany}
      />
      <RevokeClientPartnerModal
        open={revokeClientPartner}
        setOpenSuccessDialog={setOpenSuccessDialog}
        setOpen={setRevokeClientPartner}
        companyId={selectedCompany?.id}
      />

      <SuccessDialog
        openSuccessDialog={openSuccessDialog}
        setOpenSuccessDialog={setOpenSuccessDialog}
        clientPartner={selectedCompany?.client_partner?.first_name}
        company={selectedCompany?.name}
        action={action}
      />
      <LayoutContainer>
        {/* <div className="flex flex-wrap gap-8">
          <div className="flex items-center gap-8 py-4 rounded-lg grow pl-7 w-60 bg-lighterBlue lg:grow-0">
            <div className="p-[13px] bg-brandBlue rounded-2xl">
              <img src={totalCompanies} alt="" />
            </div>
            <div className="flex flex-col">
              <p className="font-medium text-tiny text-grey40">
                Total companies
              </p>
              <h2 className="text-xl font-bold text-grey100">{data?.total}</h2>
            </div>
          </div>
          <div className="flex items-center gap-8 py-4 rounded-lg grow pl-7 w-60 bg-lighterBlue md:grow-0">
            <div className="p-[13px] bg-success rounded-2xl">
              <img src={verifiedCompanies} alt="" />
            </div>
            <div className="flex flex-col">
              <p className="font-medium text-tiny text-grey40">Verified</p>
              <h2 className="text-xl font-bold text-grey100">
                {data?.verified}
              </h2>
            </div>
          </div>
          <div className="flex items-center gap-8 py-4 rounded-lg grow pl-7 w-60 bg-lighterBlue md:grow-0">
            <div className="p-[13px] bg-danger rounded-2xl">
              <img src={unverifiedCompanies} alt="" />
            </div>
            <div className="flex flex-col">
              <p className="font-medium text-tiny text-grey40">Suspended</p>
              <h2 className="text-xl font-bold text-grey100">
                {data?.suspended}
              </h2>
            </div>
          </div>
        </div> */}

        <div>
          <h4 className="mt-2 mb-4 font-medium text-grey60">Company</h4>
          <div className="flex flex-wrap ">
            <SearchInput
              placeholder="Search companies"
              onChange={text => {
                setSearchText(text);
                navigate(`?filter=${text}`);
              }}
              defaultValue={searchText}
            />
            <PrimaryButton
              buttonText="Create company"
              xPadding="px-6"
              className="font-medium ml-4"
              onClick={() => stOpenCreateCompany(!openCreateCompany)}
            />
            <Modal
              show={openCreateCompany}
              onClose={stOpenCreateCompany}
              modalTitle="Create new company"
            >
              <CreateCompanyForm
                disabled={Boolean(creatingCompany)}
                loadingState={creatingCompany}
                handleChange={handleChange}
                onSubmit={onCompanyInvite}
                input={input}
                setInput={setInput}
              />
            </Modal>

            <Modal
              modalWidth="680"
              show={openCreateCompanySuccess}
              onClose={setOpenCreateCompanySuccess}
              modalTitle
            >
              <div className="flex flex-col justify-center items-center">
                <header className="flex flex-col items-center mt-2">
                  <h4 className="mt-4 font-semibold text-grey mb-6 text-lg">
                    You have successfully {resent && 're-'}sent an invite to
                    company
                  </h4>
                </header>
                <p className="w-[418px] mb-2">
                  Your invite has been sent to the company, when they complete
                  their on-boarding, you will be notified
                </p>
                <img src={success_svg} alt="" />

                <div className="flex items-center justify-center gap-2 my-4">
                  <span className="text-sm text-grey">Powered by</span>
                  <img src={logo} alt="" width={30.55} />
                </div>
              </div>
            </Modal>
            <div className="flex flex-wrap items-center gap-4 ml-auto">
              <>
                <PrimaryButton
                  buttonText="Export table"
                  xPadding="px-6"
                  className="font-medium"
                  onClick={() => setOpen(!open)}
                />
                <Modal show={open} onClose={setOpen}>
                  <div className="flex flex-col items-center px-12 pt-20 pb-10">
                    <img className="w-[134px]" src={check_export} alt="" />
                    <h2 className="mt-4 mb-6 text-lg font-bold text-center text-grey60">
                      Your export file is being prepared. When it is ready, it
                      will be sent to the email attached to this account.
                    </h2>
                    <PrimaryButton
                      buttonText="Continue"
                      xPadding="px-6"
                      className="w-full font-medium"
                      disabled={fetchingCompanyExport}
                      loadingState={fetchingCompanyExport}
                      onClick={refetchingCompanyExport}
                    />
                    <button
                      type="button"
                      className="inline-flex justify-center w-full mt-6 text-navGrey"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Modal>
              </>
            </div>
          </div>
        </div>
        <div className="mt-3">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {!companyData?.totalRecords ? (
                <EmptyState
                  title="No company listed"
                  body="No company is listed yet"
                />
              ) : (
                <TableLayout>
                  <thead className="text-xs font-semibold uppercase">
                    <tr className="border-b border-tableGrey">
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        name
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        wallet balance
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        Status
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        client partner
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        Date created
                      </th>
                      <th className="p-3 font-medium text-tiny text-grey60 whitespace-nowrap">
                        action
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-sm divide-y divide-gray-100"
                    style={{opacity: (isFetching || creatingCompany) && 0.3}}
                  >
                    {companyData?.companies?.map(company => (
                      <tr
                        className="border-b cursor-pointer border-tableGrey"
                        key={company?.id}
                      >
                        <td
                          className="p-3 text-sm text-grey80 whitespace-nowrap"
                          onClick={() => navigate(`/company/${company?.id}/`)}
                        >
                          {company?.name}
                        </td>
                        <td
                          className="p-3 text-sm text-left text-grey80 whitespace-nowrap"
                          onClick={() => navigate(`/company/${company?.id}/`)}
                        >
                          {formatNumberToCurrency(
                            company?.wallet_balance / 100 ?? 0,
                            company?.currency,
                            settings?.settings?.dollar_exchange_rate,
                          )}
                        </td>
                        <td
                          className={`uppercase pt-[5px] pb-[3px] mt-4 px-2 text-xs font-medium text-white rounded-full w-fit flex items-center  ${
                            company?.suspended
                              ? 'bg-danger'
                              : company?.verified
                              ? 'bg-success'
                              : company?.completedRegistration
                              ? 'bg-brandBlue'
                              : company?.pendingRegistration
                              ? 'bg-yellow'
                              : 'bg-danger'
                          } whitespace-nowrap`}
                          onClick={() => navigate(`/company/${company?.id}/`)}
                        >
                          {/* {company?.verified ? 'Verified' : 'Unverified'} */}

                          {company?.suspended
                            ? 'Suspended'
                            : company?.verified
                            ? 'Verified'
                            : company?.completedRegistration
                            ? 'Onboarded'
                            : company?.pendingRegistration
                            ? 'Pending'
                            : 'unverified'}
                        </td>
                        <td
                          className="p-3 text-sm text-grey80 whitespace-nowrap"
                          onClick={() => navigate(`/company/${company?.id}/`)}
                        >
                          {`${company?.client_partner?.first_name || '-'} ${
                            company?.client_partner?.last_name || ''
                          }`}
                        </td>
                        <td
                          className="p-3 text-sm text-grey80 whitespace-nowrap"
                          onClick={() => navigate(`/company/${company?.id}/`)}
                        >
                          {moment(company?.createdAt).format(
                            'Do MMM, YYYY hh:mm',
                          ) || '-'}
                        </td>
                        <td className="p-3 text-sm text-center text-grey80 whitespace-nowrap">
                          <Dropdown>
                            <Menu.Item>
                              {() => (
                                <button
                                  onClick={() =>
                                    navigate(`/company/${company?.id}/`)
                                  }
                                  // onClick={() =>
                                  //   mutate({
                                  //     company_id: company?.id,
                                  //     type: company?.verified
                                  //       ? 'deactivate'
                                  //       : 'activate',
                                  //   })
                                  // }
                                  className={classNames(
                                    // company?.verified
                                    //   ? 'text-danger'
                                    //   : 'text-success',
                                    'text-grey60 flex items-center gap-4  text-tiny font-medium',
                                  )}
                                >
                                  <img
                                    src={eye}
                                    // src={company?.verified ? suspend : verify}
                                    alt=""
                                  />
                                  View company profile
                                  {/* {company?.verified ? 'Unverify' : 'Verify'} */}
                                </button>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              <button
                                className={classNames(
                                  company?.client_partner
                                    ? 'text-danger'
                                    : 'text-grey60',
                                  'flex items-center gap-4 text-tiny font-medium',
                                )}
                                onClick={() => {
                                  setSelectedCompany(company);

                                  if (!company?.client_partner) {
                                    return setAssignClientPartner(true);
                                  }
                                  setRevokeClientPartner(true);
                                }}
                              >
                                <img
                                  src={company?.client_partner ? suspend : user}
                                  alt=""
                                  width={16}
                                  height={16}
                                  className="relative bottom-[2px]"
                                />
                                {!company?.client_partner
                                  ? 'Assign a client partner'
                                  : 'Revoke client partner'}
                              </button>
                            </Menu.Item>

                            <Menu.Item>
                              {() => (
                                <button
                                  onClick={() => {
                                    if (
                                      !company?.verified &&
                                      !company.completedRegistration &&
                                      company.pendingRegistration &&
                                      company?.creatorPlaceholder
                                    ) {
                                      companyInvite({
                                        company: company?.name,
                                        email: company?.creatorPlaceholder,
                                      });
                                      setResent(true);
                                      return;
                                    }
                                    mutate({
                                      company_id: company?.id,
                                      type:
                                        company?.completedRegistration &&
                                        company.verified &&
                                        !company?.suspended
                                          ? 'suspend'
                                          : company?.completedRegistration &&
                                            !company.verified
                                          ? 'activate'
                                          : 'unsuspend',
                                    });
                                  }}
                                  className={classNames(
                                    company?.suspended && company?.verified
                                      ? 'text-success'
                                      : company?.verified
                                      ? 'text-danger'
                                      : !company.completedRegistration &&
                                        company.pendingRegistration &&
                                        company?.creatorPlaceholder
                                      ? 'text-grey60'
                                      : 'text-success',
                                    'flex items-center gap-4 text-tiny font-medium',
                                  )}
                                >
                                  <img
                                    src={
                                      company?.suspended
                                        ? reactivate
                                        : company?.verified
                                        ? suspend
                                        : !company.completedRegistration &&
                                          company.pendingRegistration &&
                                          company?.creatorPlaceholder
                                        ? resend
                                        : verify
                                    }
                                    alt=""
                                    width="15"
                                    className="relative bottom-[2px]"
                                  />
                                  {!company.pendingRegistration &&
                                  !company?.verified
                                    ? 'Activate company'
                                    : company?.suspended && company?.verified
                                    ? 'Reactivate company'
                                    : company?.verified && !company?.suspended
                                    ? 'Suspend'
                                    : !company.completedRegistration &&
                                      company.pendingRegistration &&
                                      company?.creatorPlaceholder
                                    ? 'Resend invite'
                                    : 'Activate company'}
                                </button>
                              )}
                            </Menu.Item>

                            {company?.completedRegistration &&
                            !company?.verified ? (
                              <Menu.Item>
                                <button
                                  onClick={() => {
                                    mutate({
                                      company_id: company?.id,
                                      type:
                                        company?.completedRegistration &&
                                        !company.verified &&
                                        !company?.suspended
                                          ? 'suspend'
                                          : company?.completedRegistration &&
                                            !company.verified
                                          ? 'unsuspend'
                                          : 'activate',
                                    });
                                  }}
                                  className={`flex items-center gap-4 text-tiny font-medium ${
                                    company?.suspended
                                      ? 'text-success'
                                      : 'text-danger'
                                  }`}
                                >
                                  <img
                                    src={
                                      company?.suspended ? reactivate : suspend
                                    }
                                    alt=""
                                    width="15"
                                    className="relative bottom-[2px]"
                                  />
                                  {company?.completedRegistration &&
                                  !company?.verified &&
                                  !company?.suspended
                                    ? 'Suspend'
                                    : 'Unsuspend company'}
                                </button>
                              </Menu.Item>
                            ) : null}

                            <Menu.Item>
                              <button
                                className="flex items-center gap-4 text-tiny font-medium text-grey60"
                                onClick={() => {
                                  setEditCompanyNameModal(true);
                                  setSelectedCompany(company);
                                }}
                              >
                                <img
                                  src={edit}
                                  alt=""
                                  width={16}
                                  height={16}
                                  className="relative bottom-[2px]"
                                />
                                Edit company name
                              </button>
                            </Menu.Item>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableLayout>
              )}
            </>
          )}

          <div className="flex flex-wrap items-baseline justify-center gap-4 mb-14 sm:justify-between">
            <Pagination
              total={(companyData && companyData?.totalPages) || 0}
              current={page}
              onPageChange={handleChangeCurrentPage}
            />
          </div>
        </div>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}

export default connect(state => state, null)(ReconciloCompany);
