import React, {useEffect, useMemo, useState} from 'react';
import Select from 'react-select';

import {customStyles, DropdownIndicator, Toggle} from '../../../components';

const walletOptions = [
  {label: 'Wallet', value: 'wallet'},
  {label: 'Collection', value: 'collection'},
];

export function SettlementConfig({settings = {}, setSettings, settlementData}) {
  const [selectedWalletOption, setSelectedWalletOption] = useState();
  const currentWalletOption = useMemo(
    () =>
      walletOptions?.find(wallet => wallet?.value === settings?.wallet_option),
    [settings?.wallet_option],
  );

  useEffect(() => {
    setSelectedWalletOption(currentWalletOption || walletOptions[0]);
  }, [currentWalletOption]);

  useEffect(() => {
    if (settlementData) {
      setSettings({
        daily_limit: settlementData?.daily_limit,
        monthly_limit: settlementData?.monthly_limit,
        wallet_option: settlementData?.wallet_option,
        enable_wallet: settlementData?.enable_wallet,
        enable_card: settlementData?.enable_card,
      });
    }
  }, [setSettings, settlementData]);

  return (
    <div title="Settlement config" className="bg-white-900 p-4 rounded">
      <p className="mb-8">Settlement config</p>

      <div>
        <section className="mt-3 grid grid-cols-1  md:grid-cols-1 gap-x-4 lg:gap-x-6 w-wull">
          <div>
            <label className="flex items-start text-left text-sm label text-grey60 mb-1">
              Wallet Mode
            </label>
            <Select
              options={walletOptions}
              value={selectedWalletOption || currentWalletOption}
              // defaultValue={selectedWalletOption || currentWalletOption}
              onChange={type => {
                setSettings({...settings, wallet_option: type?.value});
                setSelectedWalletOption(type);
              }}
              components={{DropdownIndicator}}
              classNamePrefix="react-select"
              styles={customStyles}
              menuPlacement="top"
              className="text-left bg-white"
              placeholder="Select wallet option"
            />
          </div>

          <div className="mt-4">
            <label
              htmlFor="company_tagline"
              className="flex flex-col items-start max-w-full text-sm label text-grey60"
            >
              Daily limit
              <input
                value={settings?.daily_limit ?? ''}
                onChange={e =>
                  setSettings({...settings, daily_limit: e.target.value})
                }
                id="company_tagline"
                className="w-full py-4 pl-4 mt-1 input bg-white max-w-full"
                type="text"
              />
            </label>
          </div>
          <div className="mt-4">
            <label
              htmlFor="company_tagline"
              className="flex flex-col items-start max-w-full text-sm label text-grey60"
            >
              Monthly limit
              <input
                value={settings?.monthly_limit ?? ''}
                onChange={e =>
                  setSettings({...settings, monthly_limit: e.target.value})
                }
                id="company_tagline"
                className="w-full py-4 pl-4 mt-1 input bg-white max-w-full"
                type="text"
              />
            </label>
          </div>
          <div className="flex gap-6">
            <div className="mt-4 w-full">
              <label className="flex flex-col items-start max-w-full text-sm label text-grey60">
                Enable Wallet
              </label>
              <div className="relative w-full flex gap-2 items-center justify-between cursor-pointer rounded border border-grey20 bg-white py-[14px] px-4 text-left h-12 focus:outline-none focus:border focus:border-brandBlue">
                <Toggle
                  enabled={settings?.enable_wallet}
                  setEnabled={e => setSettings({...settings, enable_wallet: e})}
                />
              </div>
            </div>
            <div className="mt-4 w-full">
              <label className="flex flex-col items-start max-w-full text-sm label text-grey60">
                Enable Card
              </label>
              <div className="relative w-full flex gap-2 items-center justify-between cursor-pointer rounded border border-grey20 bg-white py-[14px] px-4 text-left h-12 focus:outline-none focus:border focus:border-brandBlue">
                <Toggle
                  enabled={settings?.enable_card}
                  setEnabled={e => setSettings({...settings, enable_card: e})}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
