import React, {useEffect, useMemo, useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {
  DashboardLayout,
  LayoutContainer,
  PrimaryButton,
} from '../../components';
import {updateCompany} from '../../helpers/queryFns/company';
import CompanyLayout from './CompanyLayout';
import {DocumentCard} from '../../components/Company/Compliance/DocumentCard';
import {DocumentStatusModal} from '../../components/Company/Compliance/DocumentStatusModal';

const dropdownData = [
  {value: 'pending', label: 'Pending'},
  {value: 'completed', label: 'Completed'},
  {value: 'failed', label: 'Failed'},
];

function CompanyCompliance({company: {company_info}}) {
  const COMPANY = company_info?.company;

  const [slaSelected, setSLASelected] = useState({});
  const [documentUrl, setDocumentUrl] = useState('');
  const [verificationLink, setVerificationLink] = useState('');
  const [verificationSelected, setVerificationSelected] = useState({});
  const [slaOpen, setSLAOpen] = useState(false);
  const [verificationOpen, setVerificationOpen] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    setVerificationLink(COMPANY?.verification_document);
  }, [COMPANY?.verification_document]);

  const {mutate: updateCompanyInfo, isLoading: updateLoading} = useMutation(
    updateCompany,
    {
      onSuccess: data => {
        toast.success(data?.message || 'Company status updated successfully!');
        setSLAOpen(false);
        setVerificationOpen(false);
        queryClient.invalidateQueries('companyInfo-query');
      },
      onError: error => toast.error(error.response?.data?.error),
    },
  );

  const updateData = useMemo(
    () => ({
      sla_document: documentUrl || COMPANY?.sla_document,
      sla_document_status:
        slaSelected?.value || COMPANY?.sla_document_status || 'not started',
      verification_document: verificationLink || COMPANY?.verification_document,
      verification_document_status:
        verificationSelected?.value ||
        COMPANY?.verification_document_status ||
        'not started',
    }),
    [
      documentUrl,
      slaSelected?.value,
      verificationLink,
      verificationSelected?.value,
      COMPANY?.sla_document,
      COMPANY?.sla_document_status,
      COMPANY?.verification_document,
      COMPANY?.verification_document_status,
    ],
  );

  const handleUpdate = async () => {
    try {
      await updateCompanyInfo({
        company_id: COMPANY?._id,
        data: {
          ...updateData,
        },
      });
    } catch (e) {
      toast.error('');
    }
  };

  return (
    <DashboardLayout className="mt-8" headerText="Company/Compliance">
      <DocumentStatusModal
        setOpen={() => setSLAOpen(!slaOpen)}
        open={slaOpen}
        setSelected={data => setSLASelected(data)}
        selected={slaSelected}
        dropdownData={dropdownData}
        documentName="SLA Document"
      />
      <DocumentStatusModal
        setOpen={() => setVerificationOpen(!verificationOpen)}
        open={verificationOpen}
        setSelected={data => setVerificationSelected(data)}
        selected={verificationSelected}
        dropdownData={dropdownData}
        documentName="Verification Document"
      />

      <LayoutContainer>
        <CompanyLayout>
          <div className="bg-white-900 pt-8 pb-20 flex flex-col  px-6 w-full ">
            <PrimaryButton
              buttonText="Save Changes"
              xPadding="px-2"
              yPadding="py-2"
              fontSize="text-sm"
              className="font-bold self-end mb-4"
              onClick={handleUpdate}
              style={{height: 50, width: 200}}
              loadingState={updateLoading}
            />
            <div className="flex gap-16 w-full ">
              <DocumentCard
                documentName="SLA Document"
                setOpen={() => setSLAOpen(!slaOpen)}
                status={COMPANY?.sla_document_status || slaSelected?.value}
                documentUrl={COMPANY?.sla_document || documentUrl}
                setDocumentUrl={setDocumentUrl}
              />

              <DocumentCard
                documentName="Verification Document"
                setOpen={() => setVerificationOpen(!verificationOpen)}
                status={
                  COMPANY?.verification_document_status ||
                  verificationSelected?.value
                }
                documentUrl={COMPANY?.verification_document}
                setVerificationLink={setVerificationLink}
                verificationLink={verificationLink}
              />
            </div>
          </div>
        </CompanyLayout>
      </LayoutContainer>
    </DashboardLayout>
  );
}

export default connect(state => state, null)(CompanyCompliance);
