import React from 'react';
import ReactDOM from 'react-dom';
import {init as initApm} from '@elastic/apm-rum';
import * as Sentry from '@sentry/react';
import {Provider} from 'react-redux';
import {toast, ToastContainer} from 'react-toastify';
import {QueryCache, QueryClient, QueryClientProvider} from 'react-query';
import {saveState} from './helpers/persistState';
import 'rsuite/dist/rsuite.min.css';
import './index.scss';
import './app.scss';
import './tailwind.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {CheckboxContextProvider} from './helpers/context/CheckboxContext';
import Main from './Main';
import store from './store/index';
import token from './helpers/token';
import FilterContextProvider from './helpers/context/FilterContext';

initApm({
  serviceName: 'dojah-admin',
  secretToken: '',
  serverUrl:
    process.env.REACT_APP_APP_ENV_MODE === 'production'
      ? 'https://apm.dojah.services'
      : 'http://apm-apm-server.logging.svc.cluster.local:8200',
  environment: process.env.REACT_APP_APP_ENV_MODE,
});

Sentry.init({
  autoSessionTracking: true,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_APP_ENV_MODE,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      if (
        error.response &&
        error.response.status === 401 &&
        (error.response.data.error.message === 'jwt expired' ||
          error.response.data.error.message === 'jwt malfomed')
      ) {
        token.remove();
        localStorage.clear();
        window.location.reload();
        window.location = '/auth';
      }
      if (error.response && error.response.status === 500) {
        toast.error('Ops! Internal Server Error');
        return;
      }
      Sentry.captureException(error);
      toast.error(error.response?.data?.error);
    },
  }),
});

store.subscribe(() => {
  saveState(store.getState());
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <CheckboxContextProvider>
        <FilterContextProvider>
          <Main />
        </FilterContextProvider>
      </CheckboxContextProvider>
      <ToastContainer autoClose={5000} />
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
