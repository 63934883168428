import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import moment from 'moment';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import TableLayout from '../../components/TableLayout';
import CompanyLayout from './CompanyLayout';
import Loader from '../../components/Loader';
import {
  getEasyDetectProjects,
  updateProjectID,
} from '../../helpers/queryFns/fetchCompanyDetails';
import GoToTop from '../../components/GoToTop';
import Pagination from '../../components/Pagination';
import {EmptyState} from '../../components/EmptyState';
import {eye} from '../../assets/images/images';
import {Modal, PrimaryButton} from '../../components';

export default function CompanyApps() {
  const {id} = useParams();
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState('1');
  const [open, setOpen] = useState(false);
  const [scenarioId, setScenarioId] = useState('');
  const [selectedFlow, setSelectedFlow] = useState(null);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const detectQuery = useQuery(
    ['easy-detect-query', {page: currentPage, company_id: id}],
    () => getEasyDetectProjects({page: currentPage, company_id: id}),
    {
      onSuccess: data => {
        if (selectedFlow) {
          const updatedFlow = data.rows.find(
            flow => flow.id === selectedFlow.id,
          );
          if (updatedFlow) {
            setScenarioId(updatedFlow.s_id || '');
          }
        }
      },
    },
  );

  const handleEyeClick = flow => {
    setSelectedFlow(flow);
    setScenarioId(flow.s_id || '');
    setOpen(true);
  };

  const updateFlowMutation = useMutation({
    mutationFn: ({flowId, s_id}) => updateProjectID(flowId, {s_id}),
    onSuccess: () => {
      toast.success('Project updated successfully!');
      queryClient.invalidateQueries(['easy-detect-query']);
    },
    onError: error => {
      toast.error(error.message || 'Failed to update Project');
    },
  });

  const handleSubmit = async e => {
    e.preventDefault();
    if (!scenarioId.trim()) {
      toast.error('Please enter a Scenario ID');
      return;
    }

    updateFlowMutation.mutate({flowId: selectedFlow.id, s_id: scenarioId});
  };

  return (
    <DashboardLayout className="mt-4" headerText="Company/Apps">
      <LayoutContainer>
        <CompanyLayout>
          <div className="mt-5">
            {detectQuery.isLoading ? (
              <Loader />
            ) : (
              <>
                {!detectQuery?.data?.count ? (
                  <EmptyState
                    height={45}
                    title="No app listed"
                    body="No app is listed yet"
                  />
                ) : (
                  <TableLayout>
                    <thead className="text-xs font-semibold uppercase">
                      <tr className="border-b border-tableGrey">
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          Name of flow
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          use cases
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          date created
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          status
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          {' '}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {detectQuery.data?.rows.map(app => (
                        <tr className="border-b border-tableGrey" key={app?.id}>
                          <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                            {app.name ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            {app.usecase ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            {moment(app.createdAt).format(
                              'Do MMM, YYYY h:mm A',
                            ) ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            <p
                              className={`w-fit px-2 py-1 text-white rounded-lg text-xs ${
                                app.status?.toLowerCase() === 'not connected'
                                  ? 'bg-danger'
                                  : 'bg-success'
                              }`}
                            >
                              {app.status ?? '-'}
                            </p>
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            <img
                              src={eye}
                              alt="View"
                              className="w-5 h-5 cursor-pointer"
                              onClick={() => handleEyeClick(app)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </TableLayout>
                )}
              </>
            )}
          </div>

          <div className="mt-4 mb-8">
            <Pagination
              total={detectQuery?.data?.pages ?? 0}
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
              }}
            />
            {detectQuery.isFetching ? (
              <p className="border-none mt-4">Updating...</p>
            ) : null}
          </div>
        </CompanyLayout>

        <Modal show={open} onClose={setOpen}>
          <div className="p-6">
            <div className="w-full flex items-center mb-6 border-b-2 border-b-borderGrey pb-2">
              <h5 className="w-full text-grey80 justify-between text-start font-medium">
                Project Settings
              </h5>
            </div>
            <form className="w-full" onSubmit={e => e.preventDefault()}>
              <div className="bg-white-900 p-4 rounded">
                <div>
                  <label className="flex items-start text-left text-sm label text-grey60 mb-1">
                    Scenario ID
                  </label>
                  <input
                    id="scenarioId"
                    value={scenarioId}
                    onChange={e => setScenarioId(e.target.value)}
                    placeholder="Enter Scenario ID"
                    disabled={updateFlowMutation.isLoading}
                    className="w-full h-12 mt-6"
                  />
                </div>
              </div>
            </form>
            <div className="flex items-end justify-end">
              <PrimaryButton
                className="inline-flex justify-center mt-6 w-[200px] text-white rounded text-tiny bg-brandBlue"
                buttonText="Update"
                onClick={handleSubmit}
                loadingState={updateFlowMutation.isLoading}
              />
            </div>
          </div>
        </Modal>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}
