import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {dropdownBlue} from '../../assets/images/images';
import {getCompanyServices} from '../../helpers/queryFns/fetchCompanyDetails';

export default function MLServices({onUpdate}) {
  const {id} = useParams();

  const [accordion, setAccordion] = useState(false);

  const [mlTransCategorize, setMlTransCategorize] = useState('');
  const [documentAnalysis, setDocumentAnalysis] = useState('');
  const [livenessSelfie, setLivenessSelfie] = useState('');
  const [livenessVideo, setLivenessVideo] = useState('');
  const [userScreening, setUserScreening] = useState('');
  const [ipScreening, setIpScreening] = useState('');
  const [emailCheck, setEmailCheck] = useState('');
  const [phoneCheck, setPhoneCheck] = useState('');

  const mlTransServicesQuery = useQuery(['mlTrans-services-query', id], () =>
    getCompanyServices(id),
  );

  const getMlTransServices = React.useCallback(() => {
    setMlTransCategorize(
      (mlTransServicesQuery?.data?.pricing?.ml_trans_categorize / 100).toFixed(
        2,
      ),
    );
    setDocumentAnalysis(
      (
        mlTransServicesQuery?.data?.pricing?.kyc_document_analysis / 100
      ).toFixed(2),
    );
    setLivenessSelfie(
      (mlTransServicesQuery?.data?.pricing?.kyc_liveness_selfie / 100).toFixed(
        2,
      ),
    );
    setLivenessVideo(
      (mlTransServicesQuery?.data?.pricing?.kyc_liveness_video / 100).toFixed(
        2,
      ),
    );
    setUserScreening(
      (mlTransServicesQuery?.data?.pricing?.user_screening / 100).toFixed(2),
    );
    setIpScreening(
      (mlTransServicesQuery?.data?.pricing?.ip_screening / 100).toFixed(2),
    );
    setEmailCheck(
      (mlTransServicesQuery?.data?.pricing?.email_check / 100).toFixed(2),
    );
    setPhoneCheck(
      (mlTransServicesQuery?.data?.pricing?.phone_check / 100).toFixed(2),
    );
  }, [
    mlTransServicesQuery?.data?.pricing?.ml_trans_categorize,
    mlTransServicesQuery?.data?.pricing?.kyc_document_analysis,
    mlTransServicesQuery?.data?.pricing?.kyc_liveness_selfie,
    mlTransServicesQuery?.data?.pricing?.kyc_liveness_video,
    mlTransServicesQuery?.data?.pricing?.user_screening,
    mlTransServicesQuery?.data?.pricing?.ip_screening,
    mlTransServicesQuery?.data?.pricing?.email_check,
    mlTransServicesQuery?.data?.pricing?.phone_check,
  ]);

  useEffect(() => {
    getMlTransServices();
  }, [getMlTransServices]);

  const mlServicesQuery = useQuery(['ml-services-query', id], () =>
    getCompanyServices(id),
  );

  return (
    <>
      {mlServicesQuery.isLoading ? (
        '...'
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-between gap-4 mt-8">
            <div
              onClick={() => setAccordion(!accordion)}
              className="bg-lightBlue py-3 pl-4 pr-3 flex justify-between rounded-lg max-w-full w-[440px] whitespace-nowrap cursor-pointer"
            >
              <h4 className="font-medium text-grey80">ML services</h4>
              <div className="flex items-center gap-4">
                <img
                  src={dropdownBlue}
                  alt=""
                  className={`${
                    accordion ? 'rotate-180 ease-in' : 'rotate-0 ease-out'
                  } duration-200 transition`}
                />
              </div>
            </div>
          </div>

          {accordion && (
            <div className="flex justify-between flex-wrap p-6 bg-lighterBlue max-w-[440px] mt-2 rounded-lg">
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="categorize_transactions"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Categorize transactions
                    <input
                      id="categorize_transactions"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={mlTransCategorize}
                      onChange={e =>
                        onUpdate({ml_trans_categorize: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="kyc_document_analysis"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Document analysis
                    <input
                      id="kyc_document_analysis"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={documentAnalysis}
                      onChange={e =>
                        onUpdate({kyc_document_analysis: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="sentiment_analysis"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Sentiment analysis
                    <input
                      id="sentiment_analysis"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      disabled
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="img_recognition"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Image recognition
                    <input
                      id="img_recognition"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      disabled
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="user_screening"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    User Screening
                    <input
                      id="user_screening"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={userScreening}
                      onChange={e =>
                        onUpdate({user_screening: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="email_check"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Email Check
                    <input
                      id="email_check"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={emailCheck}
                      onChange={e =>
                        onUpdate({email_check: e.target.value * 100})
                      }
                    />
                  </label>
                </div>
              </div>

              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="kyc_liveness_selfie"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Liveness - Selfie
                    <input
                      id="kyc_liveness_selfie"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={livenessSelfie}
                      onChange={e => {
                        onUpdate({kyc_liveness_selfie: e.target.value * 100});
                      }}
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="kyc_liveness_video"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Liveness - Video KYC
                    <input
                      id="kyc_liveness_video"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={livenessVideo}
                      onChange={e => {
                        onUpdate({kyc_liveness_video: e.target.value * 100});
                      }}
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="img_matching"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Image matching
                    <input
                      id="img_matching"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      disabled
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="ocr_char_recognition"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    OCR (character recognition)
                    <input
                      id="ocr_char_recognition"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      disabled
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="ip_screening"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    IP Screening
                    <input
                      id="ip_screening"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={ipScreening}
                      onChange={e =>
                        onUpdate({ip_screening: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="phone_check"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Phone Check
                    <input
                      id="phone_check"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={phoneCheck}
                      onChange={e =>
                        onUpdate({phone_check: e.target.value * 100})
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
