import React from 'react';
import {Modal} from '../../index';
import SingleDropdown from '../../SingleDropdown';

function DocumentStatusModal({
  open,
  setOpen,
  dropdownData,
  selected,
  setSelected,
  documentName,
}) {
  return (
    <Modal
      show={open}
      onClose={setOpen}
      modalTitle={`Change ${documentName} status`}
    >
      <div className=" mx-auto sm:my-8 w-full h-[250px]">
        <label className="flex items-start mb-1 text-sm text-left label pb-3 text-grey60">
          {documentName} Status
        </label>
        <SingleDropdown
          dropdownData={dropdownData}
          selected={selected}
          setSelected={setSelected}
          spanText={selected?.label ?? `Select ${documentName} status...`}
          width="w-full"
          styles={{height: 148, overflowY: 'scroll'}}
          maxWidth="max-w-full"
        />
      </div>
    </Modal>
  );
}

export {DocumentStatusModal};
