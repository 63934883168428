import React, {useCallback, useMemo, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {
  // useLocation,
  useParams,
} from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import PrimaryButton from '../../components/PrimaryButton';

import {
  fetchBaasSettlement,
  updateBaasSettlement,
} from '../../helpers/queryFns/company';
import BaasSettlementLayout from './BaasSettlementLayout';
import {SettlementConfig} from './BaasSettings/SettlementConfig';

function CompanySettings() {
  const {id} = useParams();
  const [settings, setSettings] = useState();
  const queryClient = useQueryClient();
  const params = {
    id,
    // environment: state?.environment || 'live',
    environment: 'live',
  };
  const {data: settlementInfoData, isLoading: settlementInfoDataLoading} =
    useQuery(
      ['settlementInfo-query', {params}],
      () => fetchBaasSettlement({params}),
      {
        enabled: true,
      },
    );

  const updateSettlementWalletMutation = useMutation(updateBaasSettlement);

  const settlementData = useMemo(
    () =>
      !settlementInfoDataLoading &&
      settlementInfoData &&
      settlementInfoData?.entity,
    [settlementInfoData, settlementInfoDataLoading],
  );

  const handleSaveChanges = useCallback(async () => {
    if (!settings) return;

    const updateData = {
      environment: settlementData?.environment,
      id: settlementData?.id,
      daily_limit: settings?.daily_limit,
      monthly_limit: settings?.monthly_limit,
      wallet_option: settings?.wallet_option,
      enable_wallet: settings?.enable_wallet,
      enable_card: settings?.enable_card,
    };

    try {
      await updateSettlementWalletMutation?.mutateAsync(updateData, {
        onSuccess: () => {
          toast.success('Config updated successfully');
          queryClient.invalidateQueries();
        },
      });
    } catch (err) {
      toast.error(err?.response?.data?.error);
    }
  }, [
    queryClient,
    settings,
    settlementData?.environment,
    settlementData?.id,
    updateSettlementWalletMutation,
  ]);

  return (
    <DashboardLayout className="mt-4" headerText="Company/Settings">
      <LayoutContainer>
        <BaasSettlementLayout isBaas>
          <div className="mt-8">
            <div className="max-w-screen-2xl mx-auto sm:my-8">
              <div className="w-full flex items-center mb-12 border-b-2 border-b-borderGrey pb-2">
                <h5 className="w-full text-grey80 text-lg justify-between t  font-bold">
                  Configure Settlement
                </h5>

                <PrimaryButton
                  className="inline-flex justify-center w-[200px] text-white rounded text-tiny bg-brandBlue "
                  buttonText="Update config"
                  onClick={handleSaveChanges}
                  loadingState={updateSettlementWalletMutation?.isLoading}
                />
              </div>
              <form
                className="grid grid-cols-1 mb-20 md:grid-cols-2 gap-x-8 lg:gap-x-16 w-full"
                onSubmit={e => e.preventDefault()}
              >
                <div>
                  <SettlementConfig
                    setSettings={setSettings}
                    settings={settings}
                    settlementData={settlementData}
                  />
                </div>
              </form>
            </div>
          </div>
        </BaasSettlementLayout>
      </LayoutContainer>
    </DashboardLayout>
  );
}

export default CompanySettings;
