import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {enlargeIc, placeholder} from '../../../assets/images/images';
import {getStatusStyles} from '../../../helpers/getStatusStyles';
import {handleImageUpload} from '../../../helpers/handleImageUpload';
import Loader from '../../Loader';
import SecondaryButton from '../../SecondaryButton';

function DocumentCard({
  status,
  documentUrl = '',
  setOpen,
  documentName,
  setDocumentUrl,
  isLoading,
  verificationLink,
  setVerificationLink,
}) {
  const {user} = useSelector(state => state?.auth);
  const [isUploading, setIsUploading] = useState(false);

  const [documentData, setDocumentData] = useState({
    file: '',
    filename: '',
    documentImage: '',
  });

  const handleLogoUpload = async file => {
    await handleImageUpload({file, setIsUploading}, data => {
      setDocumentUrl(data?.imageUrl);
      setDocumentData({
        file: data.file,
        filename: data?.filename,
        documentImage: data?.imageUrl,
      });
    });
  };
  const resizeImage = (id, enlarge) => {
    const img = document.getElementById(id);

    if (enlarge) {
      img.classList.remove('max-h-[192px]');
    } else {
      img.classList.add('max-h-[192px]');
    }
  };
  return (
    <div className="bg-white pb-10 px-8 pt-5 rounded w-full  ">
      <p className="text-grey font-medium mb-10 ">{documentName}</p>
      <div className="flex items-center justify-between border-b border-graphGrey pb-3">
        <div
          className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full text-white ${
            getStatusStyles(status).styles
          } w-fit`}
        >
          {status || 'not started'}
        </div>
        <SecondaryButton
          buttonText="Change status"
          className="font-medium p-2 h-8 flex items-center text-[12px]"
          onClick={setOpen}
          disabled={
            user?.role?.includes('Retention') && !user?.role?.includes('Admin')
          }
        />
      </div>

      {documentName.includes('SLA') && (
        <div className="flex flex-col mt-6 gap-5 w-full ">
          <label className="text-sm label text-grey60  w-full ">
            Upload SLA Document
            <input
              accept="image/*"
              type="file"
              onChange={e => {
                const selectedFile = e.target.files[0];
                setDocumentData({
                  file: selectedFile,
                  filename: selectedFile?.name,
                });
                handleLogoUpload(selectedFile);
              }}
            />
            <div
              className={`mt-6 p-4 cursor-pointer ${
                documentUrl ? 'bg-lightBlue' : 'bg-white-900'
              } text-center border border-brandBlue border-dashed rounded w-[42vh]`}
            >
              <p
                className={`${
                  documentData?.filename ? 'text-black' : 'text-grey60'
                } text-sm font-medium w-auto`}
              >
                {documentData?.filename?.substring(0, 35) || documentUrl
                  ? 'Change SLA Document'
                  : 'Click here to upload SLA Document'}
              </p>
            </div>
          </label>

          <div className=" mt-10 w-full relative">
            <p className="text-grey60 font-medium mb-2 ">Document</p>
            {isUploading || isLoading ? (
              <Loader height={15} />
            ) : (
              <img
                src={documentUrl || placeholder}
                alt=""
                className={`w-full mt-3 object-cover ${
                  documentUrl ? 'max-h-[192px]' : 'max-w-[292px]'
                } `}
                id="id-url"
              />
            )}
            {documentUrl && !isUploading && !isLoading && (
              <div className="absolute flex items-center gap-2 right-2 bottom-2">
                <button
                  onBlur={() => resizeImage('id-url')}
                  onClick={() => resizeImage('id-url', true)}
                  className="flex items-start gap-2 px-2 py-2 text-xs font-medium rounded text-grey bg-white-900 w-fit"
                >
                  <span className="-mb-[2px]">Enlarge image</span>
                  <img src={enlargeIc} alt="" className="-mt-[2px]" />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {!documentName.includes('SLA') && (
        <div className="mt-6 flex flex-col ">
          <p className="text-grey60 font-medium mb-3 ">Verification link</p>
          <input
            type="text"
            placeholder="Enter verification link here..."
            className="w-full py-4 pl-4 mt-1 input bg-grey20 max-w-full"
            value={verificationLink}
            onChange={e => setVerificationLink(e.target.value)}
          />
        </div>
      )}
    </div>
  );
}

export {DocumentCard};
